import { h } from 'preact';
import { Link } from 'preact-router/match';
import style from './style';

const Header = () => (
	<header class={style.header}>
		<Link href="/"><p class={style.logo}>Jaap Zomer</p></Link>
	</header>
);

export default Header;
